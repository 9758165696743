import Helmet from 'react-helmet';
import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import Animation from './Animation';
import JsonLD from '../_common/JsonLD';
import Slice from '../slices/Slice';
import { ArrowRight, ArrowLeft } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';

class FrontPage extends Component {
  constructor(props) {
    super(props);

    this.sliderBeforeChange = this.sliderBeforeChange.bind(this);
    this.sliderAfterChange = this.sliderAfterChange.bind(this);

    this.sliderRef = null;

    this.initialState = {
      activeIndex: 0,
      sliderPrevDisabled: true,
      sliderNextDisabled: false,
    };

    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.sliderRef && this.sliderBeforeChange(0, 0);
    this.sliderAfterChange(0);
  }

  sliderBeforeChange(oldIndex, newIndex) {
    const video = document.querySelector('.slick-active .video');
    try {
      video.pause();
    } catch (err) {}

    const { children } = this.sliderRef.props;
    this.setState((prevState) => ({
      ...prevState,
      activeIndex: newIndex,
      sliderPrevDisabled: newIndex === 0,
      sliderNextDisabled: newIndex === children.length - 1,
    }));
  }

  sliderAfterChange(newIndex) {
    const video = document.querySelector('.slick-active .video');

    try {
      video.play();
    } catch (err) {}
  }

  render() {
    const { frontPage, jsonLd, site } = this.props;
    const { activeIndex, sliderPrevDisabled, sliderNextDisabled } = this.state;

    const settings = {
      afterChange: this.sliderAfterChange,
      arrows: false,
      beforeChange: this.sliderBeforeChange,
      className: 'super-hero__slider',
      dots: false,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
    };

    return (
      <article className="page article">
        <Helmet title={getCmsValue(site.strings.frontPage__title, site)} />
        <JsonLD jsonLd={jsonLd} />

        <section className="super-hero">
          <div className="super-hero__nav">
            <div className="super-hero__nav-wrapper">
              {frontPage.superHeros.map((superHero, i) => (
                <div
                  key={`${superHero.id}-preview`}
                  className="super-hero__nav-preview-wrapper"
                  onClick={() => this.sliderRef.slickGoTo(i)}
                >
                  <div
                    className={`super-hero__nav-preview ${
                      i === activeIndex ? 'active' : ''
                    }`}
                  >
                    <div className="super-hero__nav-preview__content">
                      <h3 className="super-hero__nav-preview__title">
                        {getCmsValue(superHero.title, site)}
                      </h3>
                      <p className="super-hero__nav-preview__caption">
                        {getCmsValue(superHero.caption, site)}
                      </p>
                    </div>
                    <span className="super-hero__nav-preview__page">
                      {i + 1}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Slider {...settings} ref={(ref) => (this.sliderRef = ref)}>
            {frontPage.superHeros.map((superHero) => (
              <Slice key={superHero.id} slice={superHero} site={site} />
            ))}
          </Slider>
          <Animation site={site} />
        </section>

        <Slice
          className="article__section"
          slice={frontPage.productGroupsSlice}
          site={site}
        />

        <Slice
          className="article__section"
          slice={frontPage.productsSlice}
          site={site}
        />

        {frontPage.slices.map((slice, i) => (
          <Slice
            className="article__section"
            key={slice.id}
            slice={slice}
            site={site}
          />
        ))}
      </article>
    );
  }
}

export default connect((state) => ({}))(FrontPage);
